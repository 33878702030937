<template>
  <v-dialog s persistent fullscreen v-model="showDialog">
    <v-card style="padding-bottom: 200px" max-width="700px" flat class="mt-5 mx-auto">
      <v-card-title class="primary white--text">11 Tage Kampagnen</v-card-title>
      <v-row no-gutters>
        <v-col class="pa-5" cols="6">
          <v-btn elevation="2" class="mr-auto ml-2 mt-2 black--text" @click="$router.push('/panel')" large icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-tabs v-model="tab" centered>
            <v-tab>Laufend</v-tab>
            <v-tab>Vergangen</v-tab>
            <v-tab>Abgeschlossen</v-tab>
            <v-tab>Kein Abschluss</v-tab>
            <v-tab v-if="userService.hasRight('stat_membercardPanel')">Statistik</v-tab>
          </v-tabs>
        </v-col>

        <v-col v-if="tab !== 4" cols="12">
          <v-text-field
            style="max-width: 1090px"
            class="mx-auto px-5 mt-5"
            v-model="search"
            append-inner-icon="mdi-magnify"
            placeholder="Suche"
            outlined
            hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="loading">
        <v-col cols="12" class="d-flex justify-center">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else-if="leads.length === 0">
        <v-col cols="12" class="d-flex justify-center">
          <v-alert max-width="700px" class="mx-5" type="info"> Keine Membercards ab dem 09.10.2024 gefunden </v-alert>
        </v-col>
      </v-row>
      <div v-else class="d-flex mx-auto" style="max-width: 1200px">
        <v-row class="mx-5">
          <v-col cols="12">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-row class="ma-1">
                  <v-col cols="12" class="d-flex justify-center">
                    <v-card-title>Anzahl: {{ filteredLeads.length }}</v-card-title>
                  </v-col>
                  <v-col v-for="lead of filteredLeads" :key="lead._id" cols="12" sm="6" md="4">
                    <membercard-lead :lead="lead"></membercard-lead>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-card-title>Anzahl: {{ pastLeads.length }}</v-card-title>
                  </v-col>
                  <v-col cols="12">
                    <v-select v-model="durationSelect" label="Zeitraum des Enddatums" outlined hide-details :items="durationItems"></v-select>
                  </v-col>
                  <v-col class="mt-0 pt-0" cols="12" v-if="pastLeads.length === 0">
                    <v-alert type="info"> Keine vergangenen Membercards ab dem 09.10.2024 gefunden </v-alert>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-row class="ma-1">
                      <v-col v-for="lead of pastLeads" :key="lead._id" cols="12" sm="6" md="4">
                        <membercard-lead :lead="lead"></membercard-lead>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-card-title>Anzahl: {{ acceptedPastLeads.length }}</v-card-title>
                  </v-col>
                  <v-col cols="12">
                    <v-select v-model="durationSelect" label="Zeitraum des Enddatums" outlined hide-details :items="durationItems"></v-select>
                  </v-col>
                  <v-col class="mt-0 pt-0" cols="12" v-if="acceptedPastLeads.length === 0">
                    <v-alert type="info"> Keine vergangenen Membercards ab dem 09.10.2024 gefunden </v-alert>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-row class="ma-1">
                      <v-col v-for="lead of acceptedPastLeads" :key="lead._id" cols="12" sm="6" md="4">
                        <membercard-lead :lead="lead"></membercard-lead>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-card-title>Anzahl: {{ declinedPastLeads.length }}</v-card-title>
                  </v-col>
                  <v-col cols="12">
                    <v-select v-model="durationSelect" label="Zeitraum des Enddatums" outlined hide-details :items="durationItems"></v-select>
                  </v-col>
                  <v-col class="mt-0 pt-0" cols="12" v-if="declinedPastLeads.length === 0">
                    <v-alert type="info"> Keine vergangenen Membercards ab dem 09.10.2024 gefunden </v-alert>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-row class="ma-1">
                      <v-col v-for="lead of declinedPastLeads" :key="lead._id" cols="12" sm="6" md="4">
                        <membercard-lead :lead="lead"></membercard-lead>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item v-if="userService.hasRight('stat_membercardPanel')">
                <v-row>
                  <v-col class="mt-5" cols="12">
                    <v-select v-model="durationSelect" label="Zeitraum" outlined hide-details :items="durationItems"> </v-select>
                  </v-col>
                  <v-col cols="12" class="mt-5">
                    <v-data-table :custom-sort="customSort" :items-per-page="-1" :headers="statsHeaders" :items="stats" class="elevation-1">
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api"
import membercardLead from "./components/membercardLead.vue"
import userService from "@/services/userService"
export default {
  components: { membercardLead },
  data() {
    return {
      userService,
      stats: [],
      statsHeaders: [
        { text: "Trainer", value: "trainer" },
        { align: "center", text: "Anz. 11 Tage Aktiviert", value: "activated" },
        { align: "center", text: "Abgeschlossen", value: "accepted" },
        { align: "center", text: "Kein Abschluss", value: "declined" },
        { align: "center", text: "Quote Mitarbeiter", value: "quote" },
        { align: "center", text: "Quote Studio", value: "studioQuote" },
      ],
      search: "",
      durationSelect: "letzter Monat",
      showDialog: true,
      leads: [],
      pastLeads: [],
      acceptedPastLeads: [],
      declinedPastLeads: [],
      loading: true,
      tab: null,
      durationItems: [
        "letzter Monat",
        "letzten 2 Monate",
        "letzten 3 Monate",
        "letzten 4 Monate",
        "letzten 5 Monate",
        "letzten 6 Monate",
        "letzten 7 Monate",
        "letzten 8 Monate",
        "letzten 9 Monate",
        "letzten 10 Monate",
        "letzten 11 Monate",
        "letzten 12 Monate",
      ],
    }
  },
  created() {
    this.showDialog = true
    this.init()
  },
  computed: {
    filteredLeads() {
      return this.leads.filter((e) => {
        if (this.search.length === 0) return true
        let fullName = (e.name + " " + e.surname).toLowerCase()
        let searchTerms = this.search.toLowerCase().trim().split(/\s+/) // Suche nach mehreren Begriffen

        // Überprüfe, ob alle Suchbegriffe im vollständigen Namen vorkommen
        return searchTerms.every((term) => fullName.includes(term))
      })
    },
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      if (!sortBy.length) return items
      const sortKey = sortBy[0]
      const descending = sortDesc[0] || false
      return items.sort((a, b) => {
        let aValue = a[sortKey]
        let bValue = b[sortKey]
        if (sortKey === "quote" || sortKey === "studioQuote") {
          aValue = parseFloat(aValue.replace("%", "")) || 0
          bValue = parseFloat(bValue.replace("%", "")) || 0
        }
        if (aValue < bValue) return descending ? 1 : -1
        if (aValue > bValue) return descending ? -1 : 1
        return 0
      })
    },
    async fetchPastMembercards() {
      try {
        this.loading = true
        let amount = (this.durationItems.indexOf(this.durationSelect) + 1) * 30
        let membercardLeads = await api.fetchPastMembercardLeads(amount)
        this.pastLeads = membercardLeads.filter((lead) => lead.status !== "Vertrag abgeschlossen" && lead.status !== "Kein Abschluss")
        this.acceptedPastLeads = membercardLeads.filter((lead) => lead.status === "Vertrag abgeschlossen")
        this.declinedPastLeads = membercardLeads.filter((lead) => lead.status === "Kein Abschluss")
      } catch (e) {
        this.$toast.error("Fehler beim Laden")
      } finally {
        this.loading = false
      }
    },
    getInitials(entry) {
      return entry.name.substr(0, 1) + entry.surname.substr(0, 1)
    },
    getPicture(entry) {
      if (!entry.roodyUser) return null
      let picture = entry.roodyUser.tac_image ? "https://app.wellcomefit.ch/api/checkin/pool/images/" + entry.roodyUser._id : null

      if (entry.roodyUser.bild) {
        picture = "/img/profilbilder" + entry.roodyUser.bild.split(".")[0] + ".webp"
      }

      return picture
    },
    async init() {
      try {
        this.loading = true
        let membercardLeads = await api.fetchMembercardLeads()
        this.leads = membercardLeads
      } catch (e) {
        this.$toast.error("Fehler beim Laden")
      } finally {
        this.loading = false
      }
    },
    async fetchStatistic() {
      let amount = (this.durationItems.indexOf(this.durationSelect) + 1) * 30
      try {
        this.loading = true
        const rawStats = await api.fetchMembercardStatistic(amount)
        this.stats = this.mapStatisticData(rawStats)
      } catch (e) {
        this.$toast.error("Fehler beim Laden der Statistik")
      } finally {
        this.loading = false
      }
    },
    mapStatisticData(rawData) {
      const grouped = {}
      let overallAccepted = 0
      let overallClosed = 0 // accepted + declined für das ganze Studio

      rawData.forEach((item) => {
        const employee = item.memberCard.employee
        const key = employee._id
        if (!grouped[key]) {
          grouped[key] = {
            trainer: `${employee.vorname} ${employee.nachname}`,
            activated: 0,
            accepted: 0,
            declined: 0,
          }
        }
        grouped[key].activated++
        if (this.$moment(item.memberCard.endDate).isBefore(this.$moment())) {
          if (item.status === "Vertrag abgeschlossen") {
            grouped[key].accepted++
            overallAccepted++
            overallClosed++
          } else {
            grouped[key].declined++
            overallClosed++
          }
        }
      })

      // Berechne die Studio-Abschlussquote einmal für alle Trainer
      const overallStudioQuote = overallClosed > 0 ? ((overallAccepted / overallClosed) * 100).toFixed(2) + "%" : "0%"

      return Object.values(grouped).map((trainerStats) => {
        const { activated, accepted, declined } = trainerStats
        trainerStats.quote = activated > 0 ? ((accepted / activated) * 100).toFixed(2) + "%" : "0%"
        trainerStats.studioQuote = overallStudioQuote
        return trainerStats
      })
    },
  },
  watch: {
    durationSelect() {
      if (this.tab === 4) {
        this.fetchStatistic()
        return
      }
      this.pastLeads = []
      this.fetchPastMembercards()
    },
    async tab() {
      if (this.tab === 4) {
        // Statistik
        this.fetchStatistic()
      }
      if (
        this.tab === 1 ||
        (this.tab === 2 && !this.pastLeads.length) ||
        !this.acceptedPastLeads.length ||
        (this.tab === 3 && !this.pastLeads.length)
      ) {
        this.fetchPastMembercards()
      } else {
      }
    },
  },
}
</script>
